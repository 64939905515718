<template>
  <div class="home">
    <router-view></router-view>
    <online></online>
  </div>
</template>

<script>
import online from '@/components/pc/p-online/p-online'
export default {
  name: "Home",
  components: {
    online
  },
};
</script>
<style scoped>
.homeheader {
  margin-bottom: 20px;
}
</style>